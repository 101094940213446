import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { useAuthenticationContext } from './AuthenticationContext'

export default function ProtectedRoute({ component: Component, ...props }) {
    const { isReady, isAuthenticated } = useAuthenticationContext()

    return (
        <Route
            {...props}
            render={props => {
                if(!isReady) {
                    return null
                }

                if (isAuthenticated) {
                    return <Component {...props} />
                }

                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {
                                from: props.location
                            }
                        }}
                    />
                )
            }}
        />
    )
}
