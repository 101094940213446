import React, { Component, Suspense, lazy } from "react";
import "babel-polyfill";
import { Router, Route, Redirect, Switch} from "react-router-dom";
import { AuthenticationContextProvider, ProtectedRoute } from './components/authentication'
import { createBrowserHistory } from 'history';

const UpgradeEng = lazy(() => import('./pages/UserManagement/UpgradeEng.js'));
const Upgrade1 = lazy(() => import('./pages/UserManagement/Upgrade1.js'));
const Subscription = lazy(() => import('./pages/UserManagement/Subscription.js'));
const ResetPasswordComplete = lazy(() => import('./pages/UserManagement/ResetPasswordComplete.js'));
const ResetPassword = lazy(() => import('./pages/UserManagement/ResetPassword.js'));
const Register = lazy(() => import('./pages/UserManagement/Register.js'));
const Login = lazy(() => import('./pages/UserManagement/Login.js'));
const ConfirmEmail = lazy(() => import('./pages/UserManagement/ConfirmEmail.js'));
const ChangePassword = lazy(() => import('./pages/UserManagement/ChangePassword.js'));
const ChangeEmail = lazy(() => import('./pages/UserManagement/ChangeEmail.js'));
const ChangeAccess = lazy(() => import('./pages/UserManagement/ChangeAccess.js'));
const Home = lazy(() => import('./pages/home/HomeF.js'));
const HomeS = lazy(() => import('./pages/home/HomeS.js'));
const CancionesLanding = lazy(() => import('./pages/Canciones/CancionesLandingF.js'));
const CancionesS = lazy(() => import('./pages/Canciones/CancionesS.js'));
const LyricPage = lazy(() => import('./pages/Canciones/LyricPageF.js'));
const LyricPageS = lazy(() => import('./pages/Canciones/LyricPageS.js'));
const BedtimeStories = lazy(() => import('./pages/Cuentos/BedtimeStoriesF.js'));
const BedtimeS = lazy(() => import('./pages/Cuentos/BedtimeS.js'));
const CuentosClasicos = lazy(() => import('./pages/Cuentos/CuentosClasicosF.js'));
const CuentosClasicosS = lazy(() => import('./pages/Cuentos/CuentosClasicosS.js'));
const CuentosIngles = lazy(() => import('./pages/Cuentos/CuentosInglesF.js'));
const CuentosInglesS = lazy(() => import('./pages/Cuentos/CuentosInglesS.js'));
const CuentosMundo = lazy(() => import('./pages/Cuentos/CuentosMundoF.js'));
const CuentosMundoS = lazy(() => import('./pages/Cuentos/CuentosMundoS.js'));
const CuentosPrincesas = lazy(() => import('./pages/Cuentos/CuentosPrincesasF.js'));
const CuentosPrincesasS = lazy(() => import('./pages/Cuentos/CuentosPrincesasS.js'));
const CuentosAnimales = lazy(() => import('./pages/Cuentos/CuentosAnimalesF.js'));
const CuentosAnimalesS = lazy(() => import('./pages/Cuentos/CuentosAnimalesS.js'));
const CuentosLatinamericanos = lazy(() => import('./pages/Cuentos/CuentosLatinamericanoF.js'));
const CuentosLatinamericanosS = lazy(() => import('./pages/Cuentos/CuentosLatinamericanoS.js'));
const Terror = lazy(() => import('./pages/Cuentos/TerrorF.js'));
const TerrorS = lazy(() => import('./pages/Cuentos/TerrorS.js'));
const CuentosLanding = lazy(() => import('./pages/Cuentos/CuentosLandingF.js'));
const CuentosLandingS = lazy(() => import('./pages/Cuentos/CuentosLandingS.js'));
const Fables = lazy(() => import('./pages/Cuentos/FablesF.js'));
const FablesS = lazy(() => import('./pages/Cuentos/FablesS.js'));
const Grimm = lazy(() => import('./pages/Cuentos/GrimmF.js'));
const GrimmS = lazy(() => import('./pages/Cuentos/GrimmS.js'));
const Legends = lazy(() => import('./pages/Cuentos/LegendsF.js'));
const LegendsS = lazy(() => import('./pages/Cuentos/LegendsS.js'));
const OriginalStories = lazy(() => import('./pages/Cuentos/OriginalStoriesF.js'));
const OriginalStoriesS = lazy(() => import('./pages/Cuentos/OriginalStoriesS.js'));
const Poems = lazy(() => import('./pages/Cuentos/PoemsF.js'));
const PoemsS = lazy(() => import('./pages/Cuentos/PoemsS.js'));
const AlphabetEnglish = lazy(() => import('./pages/English/AlphabetEnglishF.js'));
const AlphabetEnglishS = lazy(() => import('./pages/English/AlphabetEnglishS.js'));
const ColorsEnglish = lazy(() => import('./pages/English/ColorsEnglishF.js'));
const ColorsEnglishS = lazy(() => import('./pages/English/ColorsEnglishS.js'));
const EnglishGames = lazy(() => import('./pages/English/EnglishGamesF.js'));
const EnglishGamesS = lazy(() => import('./pages/English/EnglishGamesS.js'));
const FeelingsEnglish = lazy(() => import('./pages/English/FeelingsEnglishF.js'));
const FeelingsEnglishS = lazy(() => import('./pages/English/FeelingsEnglishS.js'));
const NumbersEnglish = lazy(() => import('./pages/English/NumbersEnglishF.js'));
const NumbersEnglishS = lazy(() => import('./pages/English/NumbersEnglishS.js'));
const ColorGames = lazy(() => import('./pages/JuegosInfantiles/ColorGamesF.js'));
const ColorGamesS = lazy(() => import('./pages/JuegosInfantiles/ColorGamesS.js'));
const JuegosInfantiles = lazy(() => import('./pages/JuegosInfantiles/JuegosInfantilesLandingF.js'));
const JuegosInfantilesS = lazy(() => import('./pages/JuegosInfantiles/JuegosInfantilesLandingS.js'));
const MemoryGames = lazy(() => import('./pages/JuegosInfantiles/MemoryGamesF.js'));
const MemoryGamesS = lazy(() => import('./pages/JuegosInfantiles/MemoryGamesS.js'));
const MentalGames = lazy(() => import('./pages/JuegosInfantiles/MentalGamesF.js'));
const MentalGamesS = lazy(() => import('./pages/JuegosInfantiles/MentalGamesS.js'));
const Puzzles = lazy(() => import('./pages/JuegosInfantiles/PuzzlesF.js'));
const PuzzlesS = lazy(() => import('./pages/JuegosInfantiles/PuzzlesS.js'));
const Futbol = lazy(() => import('./pages/JuegosInfantiles/FutbolF.js'));
const FutbolS = lazy(() => import('./pages/JuegosInfantiles/FutbolS.js'));
const JuegosAnimales = lazy(() => import('./pages/JuegosInfantiles/JuegosAnimalesF.js'));
const JuegosAnimalesS = lazy(() => import('./pages/JuegosInfantiles/JuegosAnimalesS.js'));
const NavidadGames = lazy(() => import('./pages/JuegosInfantiles/NavidadF.js'));
const NavidadGamesS = lazy(() => import('./pages/JuegosInfantiles/NavidadS.js'));
const AlphabetGames = lazy(() => import('./pages/Language/AlphabetGamesF.js'));
const AlphabetGamesS = lazy(() => import('./pages/Language/AlphabetGamesS.js'));
const VowelGames = lazy(() => import('./pages/Language/VowelGamesF.js'));
const VowelGamesS  = lazy(() => import('./pages/Language/VowelGamesS.js'));
const Lenguaje = lazy(() => import('./pages/Language/Lenguaje.js'));
const LenguajeS = lazy(() => import('./pages/Language/LenguajeS.js'));
const Curiosos  = lazy(() => import('./pages/Levels/CuriososF.js'));
const CuriososS = lazy(() => import('./pages/Levels/CuriososS.js'));
const Exploradores = lazy(() => import('./pages/Levels/ExploradoresF.js'));
const ExploradoresS = lazy(() => import('./pages/Levels/ExploradoresS.js'));
const Ingeniosos = lazy(() => import('./pages/Levels/IngeniososF.js'));
const IngeniososS = lazy(() => import('./pages/Levels/IngeniososS.js'));
const AdditionGames = lazy(() => import('./pages/Math/AdditionGamesFF.js'));
const AdditionGamesS = lazy(() => import('./pages/Math/AdditionGamesSS.js'));
const MathGamesLanding = lazy(() => import('./pages/Math/MathGamesLandingF.js'));
const MathGamesLandingS = lazy(() => import('./pages/Math/MathGamesLandingS.js'));
const Multiplication = lazy(() => import('./pages/Math/Multiplication/LandingF.js'));
const MultiplicationS = lazy(() => import('./pages/Math/Multiplication/LandingS.js'));
const Division = lazy(() => import('./pages/Math/DivisionF.js'));
const DivisionS = lazy(() => import('./pages/Math/DivisionS.js'));
const NumerosRomanos = lazy(() => import('./pages/Math/RomanNumerals.js'));
const NumerosRomanosS = lazy(() => import('./pages/Math/RomanNumeralsS.js'));
const Tabla1 = lazy(() => import('./pages/Math/Multiplication/Tabla1F.js'));
const Tabla1S = lazy(() => import('./pages/Math/Multiplication/Tabla1S.js'));
const Tabla2 = lazy(() => import('./pages/Math/Multiplication/Tabla2F.js'));
const Tabla2S = lazy(() => import('./pages/Math/Multiplication/Tabla2S.js'));
const Tabla3 = lazy(() => import('./pages/Math/Multiplication/Tabla3F.js'));
const Tabla3S = lazy(() => import('./pages/Math/Multiplication/Tabla3S.js'));
const Tabla4 = lazy(() => import('./pages/Math/Multiplication/Tabla4F.js'));
const Tabla4S = lazy(() => import('./pages/Math/Multiplication/Tabla4S.js'));
const Tabla5 = lazy(() => import('./pages/Math/Multiplication/Tabla5F.js'));
const Tabla5S = lazy(() => import('./pages/Math/Multiplication/Tabla5S.js'));
const Tabla6 = lazy(() => import('./pages/Math/Multiplication/Tabla6F.js'));
const Tabla6S = lazy(() => import('./pages/Math/Multiplication/Tabla6S.js'));
const Tabla7 = lazy(() => import('./pages/Math/Multiplication/Tabla7F.js'));
const Tabla7S = lazy(() => import('./pages/Math/Multiplication/Tabla7S.js'));
const Tabla8 = lazy(() => import('./pages/Math/Multiplication/Tabla8F.js'));
const Tabla8S = lazy(() => import('./pages/Math/Multiplication/Tabla8S.js'));
const Tabla9  = lazy(() => import('./pages/Math/Multiplication/Tabla9F.js'));
const Tabla9S = lazy(() => import('./pages/Math/Multiplication/Tabla9S.js'));
const Tabla10 = lazy(() => import('./pages/Math/Multiplication/Tabla10F.js'));
const Tabla10S = lazy(() => import('./pages/Math/Multiplication/Tabla10S.js'));
const MultiplicationPrintables = lazy(() => import('./pages/Math/Multiplication/PrintablesF.js'));
const MultiplicationPrintablesS = lazy(() => import('./pages/Math/Multiplication/PrintablesS.js'));
const NumberGames = lazy(() => import('./pages/Math/NumberGamesFF.js'));
const NumberGamesS = lazy(() => import('./pages/Math/NumberGamesSS.js'));
const FigurasGeometricas = lazy(() => import('./pages/Math/FigurasGeometricasF.js'));
const FigurasGeometricasS = lazy(() => import('./pages/Math/FigurasGeometricasS.js'));
const SubtractionGames = lazy(() => import('./pages/Math/SubtractionGamesF.js'));
const SubtractionGamesS = lazy(() => import('./pages/Math/SubtractionGamesS.js'));
const FAQ = lazy(() => import('./pages/termsAndConditions/FAQ.js'));
const FAQEng = lazy(() => import('./pages/termsAndConditions/FAQEng.js'));
const FAQS = lazy(() => import('./pages/termsAndConditions/FAQS.js'));
const FAQEngS = lazy(() => import('./pages/termsAndConditions/FAQEngS.js'));
const FavoritesInstructions = lazy(() => import('./pages/termsAndConditions/FavoritesInstructions.js'));
const FavoritesInstructionsS = lazy(() => import('./pages/termsAndConditions/FavoritesInstructionsS.js'));
const LinkPage = lazy(() => import('./pages/linkPage/LinkPage.js'));
const LinkPageS = lazy(() => import('./pages/linkPage/LinkPageS.js'));
const PrivacyPolicy = lazy(() => import('./pages/termsAndConditions/PrivacyPolicy.js'));
const PrivacyPolicyEng = lazy(() => import('./pages/termsAndConditions/PrivacyPolicyEng.js'));
const PrivacyPolicyEngS = lazy(() => import('./pages/termsAndConditions/PrivacyPolicyEngS.js'));
const PrivacyPolicyS = lazy(() => import('./pages/termsAndConditions/PrivacyPolicyS.js'));
const TermsPage = lazy(() => import('./pages/termsAndConditions/TermsPage.js'));
const TermsPageEng = lazy(() => import('./pages/termsAndConditions/TermsPageEng.js'));
const TermsPageS = lazy(() => import('./pages/termsAndConditions/TermsPageS.js'));
const TermsPageEngS = lazy(() => import('./pages/termsAndConditions/TermsPageEngS.js'));
const PaymentTerms = lazy(() => import('./pages/termsAndConditions/PaymentTerms.js'));
const PaymentTermsS = lazy(() => import('./pages/termsAndConditions/PaymentTermsS.js'));
const PaymentTermsEng = lazy(() => import('./pages/termsAndConditions/PaymentTermsEng.js'));
const PaymentTermsEngS = lazy(() => import('./pages/termsAndConditions/PaymentTermsEngS.js'));
const GamePage = lazy(() => import('./pages/GamePage/GamePageF.js'));
const GamePageS = lazy(() => import('./pages/GamePage/GamePageS1.js'));
const VideoPage = lazy(() => import('./pages/GamePage/VideoPageF.js'));
const SubFolderGamePage = lazy(() => import('./pages/GamePage/SubFolderGamePageF.js'));
const SubFolderGamePageS = lazy(() => import('./pages/GamePage/SubFolderGamePageS1.js'));
const AprenderGamePage = lazy(() => import('./pages/GamePage/AprenderGamePageF.js'));
const AprenderGamePageS = lazy(() => import('./pages/GamePage/AprenderGamePageS1.js'));
const SongPage = lazy(() => import('./pages/SongPage/SongPageF.js'));
const SongPageS = lazy(() => import('./pages/SongPage/SongPageS1.js'));
const StoryPage = lazy(() => import('./pages/StoryPage/StoryPageF.js'));
const StoryPageS = lazy(() => import('./pages/StoryPage/StoryPageS1.js'));
const ShortStoryPage = lazy(() => import('./pages/StoryPage/ShortStoryPageF.js'));
const ShortStoryPageS = lazy(() => import('./pages/StoryPage/ShortStoryPageS1.js'));
const PoemPage = lazy(() => import('./pages/StoryPage/PoemPageF.js'));
const PoemPageS = lazy(() => import('./pages/StoryPage/PoemPageS1.js'));
const StoryGamePage = lazy(() => import('./pages/StoryPage/StoryGamePageF.js'));
const StoryGamePageS = lazy(() => import('./pages/StoryPage/StoryGamePageS1.js'));
const ScienceGameStory = lazy(() => import('./pages/StoryPage/ScienceGameStoryF.js'));
const ScienceGameStoryS = lazy(() => import('./pages/StoryPage/ScienceGameStoryS1.js'));
const Methodology = lazy(() => import('./pages/Methodology/Methodology.js'));
const MethodologyS = lazy(() => import('./pages/Methodology/MethodologyS.js'));
const SliderBook = lazy(() => import('./pages/SliderBook/SliderBookF.js'));
const SliderBookS = lazy(() => import('./pages/SliderBook/SliderBookS1.js'));
const Refranes = lazy(() => import('./pages/Refranes/RefranesF.js'));
const RefranesS = lazy(() => import('./pages/Refranes/RefranesS.js'));
const RiddlesLanding = lazy(() => import('./pages/Riddles/RiddlesLandingF.js'));
const RiddlesLandingS = lazy(() => import('./pages/Riddles/RiddlesLandingS.js'));
const AdivinanzasAnimales = lazy(() => import('./pages/Riddles/AdivinanzasAnimalesF.js'));
const AdivinanzasAnimalesS = lazy(() => import('./pages/Riddles/AdivinanzasAnimalesS.js'));
const AdivinanzasProfesiones = lazy(() => import('./pages/Riddles/AdivinanzasProfesionesF.js'));
const AdivinanzasProfesionesS = lazy(() => import('./pages/Riddles/AdivinanzasProfesionesS.js'));
const AdivinanzasDificiles = lazy(() => import('./pages/Riddles/AdivinanzasDificilesF.js'));
const AdivinanzasDificilesS = lazy(() => import('./pages/Riddles/AdivinanzasDificilesS.js'));
const AdivinanzasFrutas = lazy(() => import('./pages/Riddles/AdivinanzasFrutasF.js'));
const AdivinanzasFrutasS = lazy(() => import('./pages/Riddles/AdivinanzasFrutasS.js'));
const AdivinanzasIngles = lazy(() => import('./pages/Riddles/AdivinanzasInglesF.js'));
const AdivinanzasInglesS = lazy(() => import('./pages/Riddles/AdivinanzasInglesS.js'));
const AdivinanzasMath = lazy(() => import('./pages/Riddles/AdivinanzasMathF.js'));
const AdivinanzasMathS = lazy(() => import('./pages/Riddles/AdivinanzasMathS.js'));
const AdivinanzasTransporte = lazy(() => import('./pages/Riddles/AdivinanzasTransporteF.js'));
const AdivinanzasTransporteS = lazy(() => import('./pages/Riddles/AdivinanzasTransporteS.js'));
const JokesLanding = lazy(() => import('./pages/Jokes/JokesLandingF.js'));
const JokesLandingS = lazy(() => import('./pages/Jokes/JokesLandingS.js'));
const Pepito = lazy(() => import('./pages/Jokes/PepitoF.js'));
const PepitoS = lazy(() => import('./pages/Jokes/PepitoS.js'));
const AnimalJokes = lazy(() => import('./pages/Jokes/AnimalJokesF.js'));
const AnimalJokesS = lazy(() => import('./pages/Jokes/AnimalJokesS.js'));
const Rhymes = lazy(() => import('./pages/Rhymes/RhymesF.js'));
const RhymesS = lazy(() => import('./pages/Rhymes/RhymesS.js'));
const TongueTwistersLanding = lazy(() => import('./pages/TongueTwisters/TongueTwistersLandingF.js'));
const TongueTwistersLandingS = lazy(() => import('./pages/TongueTwisters/TongueTwistersLandingS.js'));
const TrabalenguasCortos = lazy(() => import('./pages/TongueTwisters/TrabalenguasCortosF.js'));
const TrabalenguasCortosS = lazy(() => import('./pages/TongueTwisters/TrabalenguasCortosS.js'));
const TrabalenguasLargos = lazy(() => import('./pages/TongueTwisters/TrabalenguasLargosF.js'));
const TrabalenguasLargosS = lazy(() => import('./pages/TongueTwisters/TrabalenguasLargosS.js'));
const TrabalenguasIngles = lazy(() => import('./pages/TongueTwisters/TrabalenguasInglesF.js'));
const TrabalenguasInglesS = lazy(() => import('./pages/TongueTwisters/TrabalenguasInglesS.js'));
const DibujosLanding = lazy(() => import('./pages/Dibujos/DibujosLandingF.js'));
const DibujosLandingS = lazy(() => import('./pages/Dibujos/DibujosLandingS.js'));
const DibujosAnimales = lazy(() => import('./pages/Dibujos/DibujosAnimalesF.js'));
const DibujosAnimalesS = lazy(() => import('./pages/Dibujos/DibujosAnimalesS.js'));
const DibujosDivertidos = lazy(() => import('./pages/Dibujos/DibujosDivertidosF.js'));
const DibujosDivertidosS = lazy(() => import('./pages/Dibujos/DibujosDivertidosS.js'));
const DibujosABC = lazy(() => import('./pages/Dibujos/DibujosABCF.js'));
const DibujosABCS = lazy(() => import('./pages/Dibujos/DibujosABCS.js'));
const Dibujos123 = lazy(() => import('./pages/Dibujos/Dibujos123F.js'));
const Dibujos123S = lazy(() => import('./pages/Dibujos/Dibujos123S.js'));
const PrintablePuzzles = lazy(() => import('./pages/Dibujos/PrintablePuzzlesF.js'));
const PrintablePuzzlesS = lazy(() => import('./pages/Dibujos/PrintablePuzzlesS.js'));
const Mandalas = lazy(() => import('./pages/Dibujos/MandalasF.js'));
const MandalasS = lazy(() => import('./pages/Dibujos/MandalasS.js'));
const Diferencias = lazy(() => import('./pages/Dibujos/DiferenciasF.js'));
const DiferenciasS = lazy(() => import('./pages/Dibujos/DiferenciasS.js'));
const Draw = lazy(() => import('./pages/Dibujos/DrawF.js'));
const DrawS = lazy(() => import('./pages/Dibujos/DrawS.js'));
const Laberintos = lazy(() => import('./pages/Dibujos/LaberintosF.js'));
const LaberintosS = lazy(() => import('./pages/Dibujos/LaberintosS.js'));
const Halloween = lazy(() => import('./pages/Dibujos/HalloweenF.js'));
const HalloweenS = lazy(() => import('./pages/Dibujos/HalloweenS.js'));
const Navidad = lazy(() => import('./pages/Dibujos/NavidadF.js'));
const NavidadS = lazy(() => import('./pages/Dibujos/NavidadS.js'));
const ColoringPage = lazy(() => import('./pages/Dibujos/ColoringPageF.js'));
const ColoringPageS = lazy(() => import('./pages/Dibujos/ColoringPageS.js'));
const ColoringPage2 = lazy(() => import('./pages/Dibujos/ColoringPage2F.js'));
const ColoringPage2S = lazy(() => import('./pages/Dibujos/ColoringPage2S.js'));
const ScienceLanding = lazy(() => import('./pages/Science/ScienceLandingFF.js'));
const ScienceLandingS = lazy(() => import('./pages/Science/ScienceLandingSS.js'));
const ExperimentsLanding = lazy(() => import('./pages/Science/ExperimentsLandingFF.js'));
const ExperimentsLandingS = lazy(() => import('./pages/Science/ExperimentsLandingSS.js'));
const Technology = lazy(() => import('./pages/Science/TechnologyFF.js'));
const TechnologyS = lazy(() => import('./pages/Science/TechnologySS.js'));
const Experiments = lazy(() => import('./pages/Science/ExperimentsF.js'));
const ExperimentsS = lazy(() => import('./pages/Science/ExperimentsS1.js'));
const NewAnimalLanding = lazy(() => import('./pages/Science/NewAnimalLandingF.js'));
const NewAnimalLandingS = lazy(() => import('./pages/Science/NewAnimalLandingS.js'));
const Mamiferos = lazy(() => import('./pages/Science/AnimalSubLanding/MammalsF.js'));
const MamiferosS = lazy(() => import('./pages/Science/AnimalSubLanding/MammalsS.js'));
const Reptiles = lazy(() => import('./pages/Science/AnimalSubLanding/ReptilesF.js'));
const ReptilesS = lazy(() => import('./pages/Science/AnimalSubLanding/ReptilesS.js'));
const Birds = lazy(() => import('./pages/Science/AnimalSubLanding/BirdsF.js'));
const BirdsS = lazy(() => import('./pages/Science/AnimalSubLanding/BirdsS.js'));
const Anfibios = lazy(() => import('./pages/Science/AnimalSubLanding/AnfibiosF.js'));
const AnfibiosS = lazy(() => import('./pages/Science/AnimalSubLanding/AnfibiosS.js'));
const Fish = lazy(() => import('./pages/Science/AnimalSubLanding/FishF.js'));
const FishS = lazy(() => import('./pages/Science/AnimalSubLanding/FishS.js'));
const Invertebrates = lazy(() => import('./pages/Science/AnimalSubLanding/InvertebratesF.js'));
const InvertebratesS = lazy(() => import('./pages/Science/AnimalSubLanding/InvertebratesS.js'));
const NewAnimalArticles = lazy(() => import('./pages/Science/NewAnimalArticlesF.js'));
const NewAnimalArticlesS = lazy(() => import('./pages/Science/NewAnimalArticlesS.js'));
const AnimalBlogArticle = lazy(() => import('./pages/Science/AnimalBlogArticleF.js'));
const AnimalBlogArticleS = lazy(() => import('./pages/Science/AnimalBlogArticleS.js'));
const ScienceArticles = lazy(() => import('./pages/Science/ScienceArticlesF.js'));
const ScienceArticlesS = lazy(() => import('./pages/Science/ScienceArticlesS1.js'));
const VideosLanding = lazy(() => import('./pages/Videos/VideosLandingF.js'));
const SeriesPage = lazy(() => import('./pages/Videos/SeriesPageF.js'));
const LongSeriesPage = lazy(() => import('./pages/Videos/LongSeriesPageF.js'));
const Curriculum = lazy(() => import('./pages/Curriculum/Curriculum.js'));
const CurriculumS = lazy(() => import('./pages/Curriculum/CurriculumS.js'));
const Historia = lazy(() => import('./pages/Historia/Historia.js'));
const HistoriaS = lazy(() => import('./pages/Historia/HistoriaS.js'));
const SocialMission = lazy(() => import('./pages/SocialMission/SocialMission.js'));
const SocialMissionS = lazy(() => import('./pages/SocialMission/SocialMissionS.js'));
const FrasesEducativas = lazy(() => import('./pages/frasesEducativas/FrasesEducativasF.js'));
const FrasesEducativasS = lazy(() => import('./pages/frasesEducativas/FrasesEducativasS.js'));
const Recursos = lazy(() => import('./pages/Recursos/recursosF.js'));
const RecursosS = lazy(() => import('./pages/Recursos/recursosS.js'));
const RecursosArticles = lazy(() => import('./pages/Recursos/RecursoArticleF.js'));
const RecursosArticlesS = lazy(() => import('./pages/Recursos/RecursoArticleS.js'));
const BlogArticle = lazy(() => import('./pages/Recursos/BlogArticleF.js'));
const BlogArticleS = lazy(() => import('./pages/Recursos/BlogArticleS.js'));
const NotFound = lazy(() => import('./pages/notFound/NotFound.js'));
const Favoritos = lazy(() => import('./pages/Favoritos/Favoritos.js'));

const history = createBrowserHistory();

class App extends Component {
	constructor(props) {
        super(props)
        this.state = { stripePromise: null }
    }

	authenticationContext = React.createRef()

	onAuthenticated = ({ tokenExpiresOn }) => {
		setTimeout(this.authenticationContext.current.refresh, 
			tokenExpiresOn.getTime() - Date.now() - 5000);
	}

	onUnauthorized = () => {
		history.push('/login', {
			state: {
				from: 'unauthorized'
			}
		})
	}

  	render() {
		return (
			<AuthenticationContextProvider 
				ref={this.authenticationContext}
				onAuthenticated={this.onAuthenticated}
				onUnauthorized={this.onUnauthorized}
			>
          		{this.renderRoutes()}
      		</AuthenticationContextProvider>
    	)
  	}


	renderRoutes() {
		return (
			<Router history={history}>
				<Suspense fallback={<div>Loading...</div>}>
					<Switch>
						<Route exact path="/" component={Home} />
						<Route exact path="/register123" component={Register} />
						<Route exact path="/register/confirm/:token" component={ConfirmEmail} />
						<ProtectedRoute exact path="/plus/change-access" component={ChangeAccess}/>
						<ProtectedRoute exact path="/plus/change-email" component={ChangeEmail} />
						<ProtectedRoute exact path="/plus/change-password" component={ChangePassword} />
						<ProtectedRoute exact path="/plus/subscription" component={Subscription} />
						<Route exact path="/reset-password" component={ResetPassword} />
						<Route exact path="/login/forgot/:token" component={ResetPasswordComplete}/>
						<Route exact path="/login" component={Login} />
						<Route exact path="/subscribe" component={Upgrade1} />
						<Route exact path="/subscribe-english" component={UpgradeEng} />
						<Route exact path="/preguntas-frecuentes" component={FAQ} />
						<Route exact path="/faq" component={FAQEng} />
						<Route exact path="/como-agregar-favoritos" component={FavoritesInstructions} />
						<Route exact path="/enlazanos" component={LinkPage} />
						<Route exact path="/politica-de-privacidad" component={PrivacyPolicy} />
						<Route exact path="/privacy-policy" component={PrivacyPolicyEng} />
						<Route exact path="/terminos-y-condiciones" component={TermsPage} />
						<Route exact path="/terms-of-use" component={TermsPageEng} />
						<Route exact path="/terminos-de-pago" component={PaymentTerms} />
						<Route exact path="/payment-terms" component={PaymentTermsEng} />
						<Route exact path="/canciones-infantiles" component={CancionesLanding} />
						<Route exact path="/canciones-infantiles/:id" component={SongPage} />
						<Route exact path="/letras-imprimibles/:id" component={LyricPage} />
						<Route exact path="/cuentos-para-dormir" component={BedtimeStories} />
						<Route exact path="/cuentos-para-dormir/:id" component={StoryPage} />
						<Route exact path="/cuentos-para-dormir/cuentos-ilustrados/:id" component={SliderBook} />
						<Route exact path="/cuentos-clasicos-infantiles" component={CuentosClasicos} />
						<Route exact path="/cuentos-clasicos-infantiles/:id" component={StoryPage} />
						<Route exact path="/cuentos-clasicos-infantiles/arbol-abc/:id" component={StoryGamePage} />
						<Route exact path="/cuentos-infantiles-cortos" component={CuentosLanding} />
						<Route exact path="/fabulas-para-niños" component={Fables} />
						<Route exact path="/fabulas-para-niños/:id" component={ShortStoryPage} />
						<Route exact path="/cuentos-en-ingles" component={CuentosIngles} />
						<Route exact path="/cuentos-en-ingles/clasicos/:id" component={ShortStoryPage} />
						<Route exact path="/cuentos-en-ingles/cuentos-ilustrados/:id" component={SliderBook} />
						<Route exact path="/cuentos-hermanos-grimm" component={Grimm} />
						<Route exact path="/cuentos-hermanos-grimm/:id" component={StoryPage} />
						<Route exact path="/cuentos-de-princesas" component={CuentosPrincesas} />
						<Route exact path="/cuentos-de-princesas/:id" component={StoryPage} />
						<Route exact path="/cuentos-de-animales" component={CuentosAnimales} />
						<Route exact path="/cuentos-latinoamericanos" component={CuentosLatinamericanos} />
						<Route exact path="/cuentos-latinoamericanos/:id" component={StoryPage} />
						<Route exact path="/cuentos-del-mundo" component={CuentosMundo} />
						<Route exact path="/cuentos-del-mundo/:id" component={StoryPage} />
						<Route exact path="/cuentos-de-terror-cortos" component={Terror} />
						<Route exact path="/cuentos-de-terror-cortos/:id" component={ShortStoryPage} />
						<Route exact path="/mitos-y-leyendas-para-niños" component={Legends} />
						<Route exact path="/mitos-y-leyendas-para-niños/:id" component={ShortStoryPage} />
						<Route exact path="/cuentos-inventados" component={OriginalStories} />
						<Route exact path="/cuentos-inventados/:id" component={StoryPage} />
						<Route exact path="/cuentos-inventados/cuentos-ilustrados/:id" component={SliderBook} />
						<Route exact path="/cuentos-inventados/arbol-abc/:id" component={StoryGamePage} />
						<Route exact path="/poemas-para-niños" component={Poems} />
						<Route exact path="/poemas-para-niños/:id" component={PoemPage} />
						<Route exact path="/abecedario-en-ingles" component={AlphabetEnglish} />
						<Route exact path="/abecedario-en-ingles/:id" component={GamePage} />
						<Route exact path="/colores-en-ingles" component={ColorsEnglish} />
						<Route exact path="/colores-en-ingles/:id" component={GamePage} />
						<Route exact path="/juegos-de-ingles" component={EnglishGames} />
						<Route exact path="/vocabulario-en-ingles" component={FeelingsEnglish} />
						<Route exact path="/vocabulario-en-ingles/:id" component={GamePage} />
						<Route exact path="/numeros-en-ingles" component={NumbersEnglish} />
						<Route exact path="/numeros-en-ingles/:id" component={GamePage} />
						<Route exact path="/juegos-de-colores" component={ColorGames} /> 
						<Route exact path="/juegos-de-colores/:id" component={GamePage} /> 
						<Route exact path="/juegos-infantiles" component={JuegosInfantiles} /> 
						<Route exact path="/juegos-de-memoria" component={MemoryGames} /> 
						<Route exact path="/juegos-de-memoria/:id" component={GamePage} /> 
						<Route exact path="/juegos-de-estrategia" component={MentalGames} /> 
						<Route exact path="/juegos-de-estrategia/:id" component={GamePage} />  
						<Route exact path="/juegos-de-futbol" component={Futbol} />
						<Route exact path="/juegos-de-futbol/:id" component={GamePage} />
						<Route exact path="/rompecabezas-para-niños" component={Puzzles} /> 
						<Route exact path="/rompecabezas-para-niños/:id" component={GamePage} /> 
						<Route exact path="/juegos-de-navidad" component={NavidadGames} /> 
						<Route exact path="/juegos-de-navidad/:id" component={GamePage} /> 
						<Route exact path="/juegos-del-abecedario" component={AlphabetGames} /> 
						<Route exact path="/juegos-del-abecedario/:id" component={GamePage} />  
						<Route exact path="/juegos-de-lenguaje" component={Lenguaje} /> 
						<Route exact path="/juegos-de-lenguaje/:id" component={GamePage} />  
						<Route exact path="/prelectores/:id" component={AprenderGamePage} />
						<Route exact path="/lectores-emergentes/:id" component={AprenderGamePage} />
						<Route exact path="/juegos-de-vocales" component={VowelGames} /> 
						<Route exact path="/juegos-de-vocales/:id" component={GamePage} /> 
						<Route exact path="/juegos-para-ninos-6-y-7-anos" component={Curiosos} /> 
						<Route exact path="/juegos-para-ninos-de-preescolar" component={Exploradores} /> 
						<Route exact path="/lecturas-cortas-para-niños" component={Ingeniosos} /> 
						<Route exact path="/juegos-de-sumas" component={AdditionGames} /> 
						<Route exact path="/juegos-de-sumas/:id" component={GamePage} /> 
						<Route exact path="/juegos-de-matematicas" component={MathGamesLanding} /> 
						<Route exact path="/divisiones" component={Division} />
						<Route exact path="/divisiones/:id" component={GamePage} /> 
						<Route exact path="/fracciones/:id" component={GamePage} />
						<Route exact path="/numeros-decimales/:id" component={GamePage} />
						<Route exact path="/numeros-romanos" component={NumerosRomanos} />
						<Route exact path="/juegos-tablas-de-multiplicar" component={Multiplication} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-1" component={Tabla1} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-1/:id" component={SubFolderGamePage} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-2" component={Tabla2} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-2/:id" component={SubFolderGamePage} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-3" component={Tabla3} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-3/:id" component={SubFolderGamePage} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-4" component={Tabla4} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-4/:id" component={SubFolderGamePage} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-5" component={Tabla5} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-5/:id" component={SubFolderGamePage} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-6" component={Tabla6} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-6/:id" component={SubFolderGamePage} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-7" component={Tabla7} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-7/:id" component={SubFolderGamePage} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-8" component={Tabla8} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-8/:id" component={SubFolderGamePage} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-9" component={Tabla9} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-9/:id" component={SubFolderGamePage} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-10" component={Tabla10} />
						<Route exact path="/juegos-tablas-de-multiplicar/tabla-del-10/:id" component={SubFolderGamePage} />
						<Route exact path="/juegos-tablas-de-multiplicar/mixta/:id" component={SubFolderGamePage} />
						<Route exact path="/juegos-tablas-de-multiplicar/tablas-imprimibles/:id" component={MultiplicationPrintables} />
						<Route exact path="/juegos-de-numeros" component={NumberGames} /> 
						<Route exact path="/juegos-de-numeros/:id" component={GamePage} /> 
						<Route exact path="/juegos-de-figuras-geometricas/:id" component={GamePage} /> 
						<Route exact path="/figuras-geometricas" component={FigurasGeometricas} />
						<Route exact path="/juegos-de-restas" component={SubtractionGames} /> 
						<Route exact path="/juegos-de-restas/:id" component={GamePage} /> 
						<Route exact path="/metodologia" component={Methodology} />
						<Route exact path="/sliderbook" component={SliderBook} />
						<Route exact path="/refranes" component={Refranes} />
						<Route exact path="/rimas-para-ninos" component={Rhymes} />
						<Route exact path="/adivinanzas-para-ninos" component={RiddlesLanding} />
						<Route exact path="/adivinanzas-de-animales" component={AdivinanzasAnimales} />
						<Route exact path="/adivinanzas-de-profesiones" component={AdivinanzasProfesiones} />
						<Route exact path="/adivinanzas-matematicas-y-numeros" component={AdivinanzasMath} />
						<Route exact path="/adivinanzas-de-frutas" component={AdivinanzasFrutas} />
						<Route exact path="/adivinanzas-dificiles" component={AdivinanzasDificiles} />
						<Route exact path="/adivinanzas-en-ingles" component={AdivinanzasIngles} />
						<Route exact path="/adivinanzas-de-transporte" component={AdivinanzasTransporte} />
						<Route exact path="/chistes-cortos-para-ninos" component={JokesLanding} />
						<Route exact path="/chistes-de-pepito-para-ninos" component={Pepito} />
						<Route exact path="/chistes-de-animales-para-ninos" component={AnimalJokes} />
						<Route exact path="/trabalenguas-para-ninos" component={TongueTwistersLanding} />
						<Route exact path="/trabalenguas-para-ninos/cortos" component={TrabalenguasCortos} />
						<Route exact path="/trabalenguas-para-ninos/dificiles" component={TrabalenguasLargos} />
						<Route exact path="/trabalenguas-para-ninos/en-ingles" component={TrabalenguasIngles} />
						<Route exact path="/dibujos-para-colorear" component={DibujosLanding} />
						<Route exact path="/dibujos-para-colorear/animales" component={DibujosAnimales} />
						<Route exact path="/dibujos-para-colorear/animales/:id" component={ColoringPage} />
						<Route exact path="/dibujos-para-colorear/divertidos" component={DibujosDivertidos} />
						<Route exact path="/dibujos-para-colorear/divertidos/:id" component={ColoringPage} />
						<Route exact path="/juegos-de-laberintos-para-ninos" component={Laberintos} />
						<Route exact path="/juegos-de-laberintos-para-ninos/:id" component={ColoringPage2} />
						<Route exact path="/rompecabezas-imprimibles/:id" component={PrintablePuzzles} />
						<Route exact path="/dibujos-para-colorear/abecedario" component={DibujosABC} />
						<Route exact path="/dibujos-para-colorear/abecedario/:id" component={ColoringPage} />
						<Route exact path="/dibujos-para-colorear/numeros" component={Dibujos123} />
						<Route exact path="/dibujos-para-colorear/numeros/:id" component={ColoringPage} />
						<Route exact path="/dibujos-para-colorear/figuras-geometricas/:id" component={ColoringPage} />
						<Route exact path="/dibujos-para-colorear/mandalas" component={Mandalas} />
						<Route exact path="/dibujos-para-colorear/mandalas/:id" component={ColoringPage} />
						<Route exact path="/encuentra-las-diferencias" component={Diferencias} />
						<Route exact path="/encuentra-las-diferencias/:id" component={ColoringPage2} />
						<Route exact path="/dibujos-para-dibujar" component={Draw} />
						<Route exact path="/dibujos-para-dibujar/:id" component={ColoringPage2} />
						<Route exact path="/dibujos-para-colorear/halloween" component={Halloween} />
						<Route exact path="/dibujos-para-colorear/halloween/:id" component={ColoringPage} />
						<Route exact path="/dibujos-para-colorear/navidad" component={Navidad} />
						<Route exact path="/dibujos-para-colorear/navidad/:id" component={ColoringPage} />
						<Route exact path="/ciencia-para-niños" component={ScienceLanding} />
						<Route exact path="/experimentos-caseros-para-niños" component={ExperimentsLanding} />
						<Route exact path="/experimentos-caseros-para-niños/:id" component={Experiments} />
						<Route exact path="/ciencias-tecnologia" component={Technology} />
						<Route exact path="/ciencias-tecnologia/articulos/:id" component={ScienceArticles} />
						<Route exact path="/ciencias-tecnologia/libros/:id" component={SliderBook} />
						<Route exact path="/ciencias-tecnologia/arbol-abc/:id" component={ScienceGameStory} />
						<Route exact path="/animales-para-ninos" component={NewAnimalLanding} />
						<Route exact path="/animales-para-ninos/mamiferos" component={Mamiferos} />
						<Route exact path="/animales-para-ninos/mamiferos/:id" component={NewAnimalArticles} />
						<Route exact path="/animales-para-ninos/reptiles" component={Reptiles} />
						<Route exact path="/animales-para-ninos/reptiles/:id" component={NewAnimalArticles} />
						<Route exact path="/animales-para-ninos/aves" component={Birds} />
						<Route exact path="/animales-para-ninos/aves/:id" component={NewAnimalArticles} />
						<Route exact path="/animales-para-ninos/anfibios" component={Anfibios} />
						<Route exact path="/animales-para-ninos/anfibios/:id" component={NewAnimalArticles} />
						<Route exact path="/animales-para-ninos/peces" component={Fish} />
						<Route exact path="/animales-para-ninos/peces/:id" component={NewAnimalArticles} />
						<Route exact path="/animales-para-ninos/invertebrados" component={Invertebrates} />
						<Route exact path="/animales-para-ninos/invertebrados/:id" component={NewAnimalArticles} />
						<Route exact path="/animales-para-ninos/articulos/:id" component={AnimalBlogArticle} />
						<Route exact path="/juegos-de-animales" component={JuegosAnimales} />
						<Route exact path="/juegos-de-animales/:id" component={GamePage} />
						<Route exact path="/dibujos-animados" component={VideosLanding} />
						<Route exact path="/dibujos-animados/om-nom" component={LongSeriesPage} />
						<Route exact path="/dibujos-animados/om-nom/:id" component={VideoPage} />
						<Route exact path="/dibujos-animados/bernard" component={LongSeriesPage} />
						<Route exact path="/dibujos-animados/bernard/:id" component={VideoPage} />
						<Route exact path="/dibujos-animados/qumi-qumi" component={LongSeriesPage} />
						<Route exact path="/dibujos-animados/qumi-qumi/:id" component={VideoPage} />
						<Route exact path="/dibujos-animados/menuda-noche" component={SeriesPage} />
						<Route exact path="/dibujos-animados/menuda-noche/:id" component={VideoPage} />
						<Route exact path="/dibujos-animados/number-farm" component={SeriesPage} />
						<Route exact path="/dibujos-animados/number-farm/:id" component={VideoPage} />
						<Route exact path="/dibujos-animados/learning-zoo" component={SeriesPage} />
						<Route exact path="/dibujos-animados/learning-zoo/:id" component={VideoPage} />
						<Route exact path="/dibujos-animados/rockalingua" component={SeriesPage} />
						<Route exact path="/dibujos-animados/rockalingua/:id" component={VideoPage} />
						<Route exact path="/dibujos-animados/toonimals" component={LongSeriesPage} />
						<Route exact path="/dibujos-animados/toonimals/:id" component={VideoPage} />
						<Route exact path="/dibujos-animados/suckers" component={LongSeriesPage} />
						<Route exact path="/dibujos-animados/suckers/:id" component={VideoPage} />
						<Route exact path="/dibujos-animados/las-aventuras-minchi" component={LongSeriesPage} />
						<Route exact path="/dibujos-animados/las-aventuras-minchi/:id" component={VideoPage} />
						<Route exact path="/curriculo" component={Curriculum} />
						<Route exact path="/mision-social" component={SocialMission} />
						<Route exact path="/nuestra-historia" component={Historia} />
						<Route exact path="/frases-de-educacion" component={FrasesEducativas} />
						<Route exact path="/juegos-de-preguntas/:id" component={GamePage} /> 
						<Route exact path="/material-educativo" component={Recursos} />
						<Route exact path="/material-educativo/:id" component={RecursosArticles} />
						<Route exact path="/recursos/:id" component={BlogArticle} />
						<ProtectedRoute exact path="/plus/preguntas-frecuentes" component={FAQS} />
						<ProtectedRoute exact path="/plus/faq" component={FAQEngS} />
						<ProtectedRoute exact path="/plus/politica-de-privacidad" component={PrivacyPolicyS} />
						<ProtectedRoute exact path="/plus/privacy-policy" component={PrivacyPolicyEngS} />
						<ProtectedRoute exact path="/plus/terminos-y-condiciones" component={TermsPageS} />
						<ProtectedRoute exact path="/plus/terms-of-use" component={TermsPageEngS} />
						<ProtectedRoute exact path="/plus/terminos-de-pago" component={PaymentTermsS} />
						<ProtectedRoute exact path="/plus/payment-terms" component={PaymentTermsEngS} />
						<ProtectedRoute exact path="/plus/canciones-infantiles" component={CancionesS} />
						<ProtectedRoute exact path="/plus/canciones-infantiles/:id" component={SongPageS} />
						<ProtectedRoute exact path="/plus/letras-imprimibles/:id" component={LyricPageS} />
						<ProtectedRoute exact path="/plus/cuentos-para-dormir" component={BedtimeS} />
						<ProtectedRoute exact path="/plus/cuentos-para-dormir/:id" component={StoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-para-dormir/cuentos-ilustrados/:id" component={SliderBookS} />
						<ProtectedRoute exact path="/plus/cuentos-clasicos-infantiles" component={CuentosClasicosS} />
						<ProtectedRoute exact path="/plus/cuentos-clasicos-infantiles/:id" component={StoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-clasicos-infantiles/arbol-abc/:id" component={StoryGamePageS} />
						<ProtectedRoute exact path="/plus/cuentos-en-ingles" component={CuentosInglesS} />
						<ProtectedRoute exact path="/plus/cuentos-en-ingles/clasicos/:id" component={ShortStoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-en-ingles/cuentos-ilustrados/:id" component={SliderBookS} />
						<ProtectedRoute exact path="/plus/cuentos-del-mundo" component={CuentosMundoS} />
						<ProtectedRoute exact path="/plus/cuentos-del-mundo/:id" component={StoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-de-princesas" component={CuentosPrincesasS} />
						<ProtectedRoute exact path="/plus/cuentos-de-princesas/:id" component={StoryPageS} />
						<ProtectedRoute exact path="/plus/fabulas-para-niños" component={FablesS} />
						<ProtectedRoute exact path="/plus/fabulas-para-niños/:id" component={ShortStoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-hermanos-grimm" component={GrimmS} />
						<ProtectedRoute exact path="/plus/cuentos-hermanos-grimm/:id" component={StoryPageS} />
						<ProtectedRoute exact path="/plus/mitos-y-leyendas-para-niños" component={LegendsS} />
						<ProtectedRoute exact path="/plus/mitos-y-leyendas-para-niños/:id" component={ShortStoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-inventados" component={OriginalStoriesS} />
						<ProtectedRoute exact path="/plus/cuentos-inventados/:id" component={StoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-inventados/cuentos-ilustrados/:id" component={SliderBookS} />
						<ProtectedRoute exact path="/plus/cuentos-inventados/arbol-abc/:id" component={StoryGamePageS} />
						<ProtectedRoute exact path="/plus/poemas-para-niños" component={PoemsS} />
						<ProtectedRoute exact path="/plus/poemas-para-niños/:id" component={PoemPageS} />
						<ProtectedRoute exact path="/plus/cuentos-de-terror-cortos" component={TerrorS} />
						<ProtectedRoute exact path="/plus/cuentos-de-animales" component={CuentosAnimalesS} />
						<ProtectedRoute exact path="/plus/cuentos-latinoamericanos" component={CuentosLatinamericanosS} />
						<ProtectedRoute exact path="/plus/cuentos-latinoamericanos/:id" component={StoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-de-terror-cortos/:id" component={ShortStoryPageS} />
						<ProtectedRoute exact path="/plus/cuentos-infantiles-cortos" component={CuentosLandingS} />
						<ProtectedRoute exact path="/plus/curriculo" component={CurriculumS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/numeros" component={Dibujos123S} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/numeros/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/abecedario" component={DibujosABCS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/abecedario/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/animales" component={DibujosAnimalesS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/animales/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/divertidos" component={DibujosDivertidosS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/divertidos/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear" component={DibujosLandingS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/figuras-geometricas/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/encuentra-las-diferencias" component={DiferenciasS} />
						<ProtectedRoute exact path="/plus/encuentra-las-diferencias/:id" component={ColoringPage2S} />
						<ProtectedRoute exact path="/plus/dibujos-para-dibujar" component={DrawS} />
						<ProtectedRoute exact path="/plus/dibujos-para-dibujar/:id" component={ColoringPage2S} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/halloween" component={HalloweenS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/halloween/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/juegos-de-laberintos-para-ninos" component={LaberintosS} />
						<ProtectedRoute exact path="/plus/juegos-de-laberintos-para-ninos/:id" component={ColoringPage2S} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/mandalas" component={MandalasS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/mandalas/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/navidad" component={NavidadS} />
						<ProtectedRoute exact path="/plus/dibujos-para-colorear/navidad/:id" component={ColoringPageS} />
						<ProtectedRoute exact path="/plus/rompecabezas-imprimibles/:id" component={PrintablePuzzlesS} />
						<ProtectedRoute exact path="/plus/abecedario-en-ingles" component={AlphabetEnglishS} />
						<ProtectedRoute exact path="/plus/abecedario-en-ingles/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/colores-en-ingles" component={ColorsEnglishS} />
						<ProtectedRoute exact path="/plus/colores-en-ingles/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/juegos-de-ingles" component={EnglishGamesS} />
						<ProtectedRoute exact path="/plus/vocabulario-en-ingles" component={FeelingsEnglishS} />
						<ProtectedRoute exact path="/plus/vocabulario-en-ingles/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/numeros-en-ingles" component={NumbersEnglishS} />
						<ProtectedRoute exact path="/plus/numeros-en-ingles/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/frases-de-educacion" component={FrasesEducativasS} />
						<ProtectedRoute exact path="/plus/nuestra-historia" component={HistoriaS} />
						<ProtectedRoute exact path="/plus" component={HomeS} />
						<ProtectedRoute exact path="/plus/chistes-de-animales-para-ninos" component={AnimalJokesS} />
						<ProtectedRoute exact path="/plus/chistes-cortos-para-ninos" component={JokesLandingS} />
						<ProtectedRoute exact path="/plus/chistes-de-pepito-para-ninos" component={PepitoS} />
						<ProtectedRoute exact path="/plus/juegos-de-colores" component={ColorGamesS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-colores/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-futbol" component={FutbolS} />
						<ProtectedRoute exact path="/plus/juegos-de-futbol/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/juegos-infantiles" component={JuegosInfantilesS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-memoria" component={MemoryGamesS} />
						<ProtectedRoute exact path="/plus/juegos-de-memoria/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-estrategia" component={MentalGamesS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-estrategia/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-navidad" component={NavidadGamesS} />
						<ProtectedRoute exact path="/plus/juegos-de-navidad/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/rompecabezas-para-niños" component={PuzzlesS} /> 
						<ProtectedRoute exact path="/plus/rompecabezas-para-niños/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-del-abecedario" component={AlphabetGamesS} /> 
						<ProtectedRoute exact path="/plus/juegos-del-abecedario/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-lenguaje" component={LenguajeS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-lenguaje/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/game-test/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-vocales" component={VowelGamesS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-vocales/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/prelectores/:id" component={AprenderGamePageS} />
						<ProtectedRoute exact path="/plus/lectores-emergentes/:id" component={AprenderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-para-ninos-6-y-7-anos" component={CuriososS} /> 
						<ProtectedRoute exact path="/plus/juegos-para-ninos-de-preescolar" component={ExploradoresS} /> 
						<ProtectedRoute exact path="/plus/lecturas-cortas-para-niños" component={IngeniososS} /> 
						<ProtectedRoute exact path="/plus/enlazanos" component={LinkPageS} />
						<ProtectedRoute exact path="/plus/divisiones" component={DivisionS} />
						<ProtectedRoute exact path="/plus/divisiones/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/fracciones/:id" component={GamePageS} />  
						<ProtectedRoute exact path="/plus/numeros-decimales/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/numeros-romanos" component={NumerosRomanosS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar" component={MultiplicationS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tablas-imprimibles/:id" component={MultiplicationPrintablesS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-1" component={Tabla1S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-1/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-2" component={Tabla2S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-2/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-3" component={Tabla3S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-3/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-4" component={Tabla4S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-4/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-5" component={Tabla5S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-5/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-6" component={Tabla6S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-6/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-7" component={Tabla7S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-7/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-8" component={Tabla8S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-8/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-9" component={Tabla9S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-9/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-10" component={Tabla10S} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/tabla-del-10/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-tablas-de-multiplicar/mixta/:id" component={SubFolderGamePageS} />
						<ProtectedRoute exact path="/plus/juegos-de-sumas" component={AdditionGamesS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-sumas/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-matematicas" component={MathGamesLandingS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-numeros" component={NumberGamesS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-numeros/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/figuras-geometricas" component={FigurasGeometricasS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-figuras-geometricas/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/juegos-de-restas" component={SubtractionGamesS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-restas/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/juegos-de-preguntas/:id" component={GamePageS} /> 
						<ProtectedRoute exact path="/plus/metodologia" component={MethodologyS} />
						<ProtectedRoute exact path="/plus/material-educativo" component={RecursosS} />
						<ProtectedRoute exact path="/plus/material-educativo/:id" component={RecursosArticlesS} />
						<ProtectedRoute exact path="/plus/adivinanzas-de-animales" component={AdivinanzasAnimalesS} />
						<ProtectedRoute exact path="/plus/adivinanzas-dificiles" component={AdivinanzasDificilesS} />
						<ProtectedRoute exact path="/plus/adivinanzas-de-frutas" component={AdivinanzasFrutasS} />
						<ProtectedRoute exact path="/plus/adivinanzas-en-ingles" component={AdivinanzasInglesS} />
						<ProtectedRoute exact path="/plus/adivinanzas-matematicas-y-numeros" component={AdivinanzasMathS} />
						<ProtectedRoute exact path="/plus/adivinanzas-de-profesiones" component={AdivinanzasProfesionesS} />
						<ProtectedRoute exact path="/plus/adivinanzas-de-transporte" component={AdivinanzasTransporteS} />
						<ProtectedRoute exact path="/plus/adivinanzas-para-ninos" component={RiddlesLandingS} />
						<ProtectedRoute exact path="/plus/refranes" component={RefranesS} />
						<ProtectedRoute exact path="/plus/rimas-para-ninos" component={RhymesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos" component={NewAnimalLandingS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/articulos/:id" component={AnimalBlogArticleS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/mamiferos" component={MamiferosS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/mamiferos/:id" component={NewAnimalArticlesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/reptiles" component={ReptilesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/reptiles/:id" component={NewAnimalArticlesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/aves" component={BirdsS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/aves/:id" component={NewAnimalArticlesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/anfibios" component={AnfibiosS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/anfibios/:id" component={NewAnimalArticlesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/peces" component={FishS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/peces/:id" component={NewAnimalArticlesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/invertebrados" component={InvertebratesS} />
						<ProtectedRoute exact path="/plus/animales-para-ninos/invertebrados/:id" component={NewAnimalArticlesS} />
						<ProtectedRoute exact path="/plus/juegos-de-animales" component={JuegosAnimalesS} />
						<ProtectedRoute exact path="/plus/juegos-de-animales/:id" component={GamePageS} />
						<ProtectedRoute exact path="/plus/experimentos-caseros-para-niños" component={ExperimentsLandingS} />
						<ProtectedRoute exact path="/plus/experimentos-caseros-para-niños/:id" component={ExperimentsS} />
						<ProtectedRoute exact path="/plus/ciencia-para-niños" component={ScienceLandingS} />
						<ProtectedRoute exact path="/plus/ciencias-tecnologia" component={TechnologyS} />
						<ProtectedRoute exact path="/plus/ciencias-tecnologia/articulos/:id" component={ScienceArticlesS} />
						<ProtectedRoute exact path="/plus/ciencias-tecnologia/libros/:id" component={SliderBookS} />
						<ProtectedRoute exact path="/plus/ciencias-tecnologia/arbol-abc/:id" component={ScienceGameStoryS} />
						<ProtectedRoute exact path="/plus/mision-social" component={SocialMissionS} />
						<ProtectedRoute exact path="/plus/trabalenguas-para-ninos" component={TongueTwistersLandingS} />
						<ProtectedRoute exact path="/plus/trabalenguas-para-ninos/cortos" component={TrabalenguasCortosS} />
						<ProtectedRoute exact path="/plus/trabalenguas-para-ninos/en-ingles" component={TrabalenguasInglesS} />
						<ProtectedRoute exact path="/plus/trabalenguas-para-ninos/dificiles" component={TrabalenguasLargosS} />
						<ProtectedRoute exact path="/plus/como-agregar-favoritos" component={FavoritesInstructionsS} />
						<ProtectedRoute exact path="/plus/favoritos" component={Favoritos} />
						<ProtectedRoute exact path="/plus/recursos/:id" component={BlogArticleS} />
						<Redirect from="/index.html" to="/" />
						<Redirect from="/dibujos-de-animales-para-colorear" to="/dibujos-para-colorear/animales" />
						<Redirect from="/dibujos-figuras-geometricas" to="/dibujos-para-colorear/figuras-geometricas" />
						<Redirect from="/numeros-para-colorear" to="/dibujos-para-colorear/numeros" />
						<Redirect from="/abecedario-para-colorear" to="/dibujos-para-colorear/abecedario" />
						<Redirect from="/dibujos-divertidos-para-colorear" to="/dibujos-para-colorear/divertidos" />
						<Redirect from="/dibujos-mandalas-para-colorear" to="/dibujos-para-colorear/mandalas" />
						<Redirect from="/dibujos-de-halloween-para-colorear" to="/dibujos-para-colorear/halloween" />
						<Redirect from="/dibujos-de-navidad-para-colorear" to="/dibujos-para-colorear/navidad" />
						<Redirect from="/dibujos-para-colorear-faciles" to="/dibujos-para-colorear" />
						<Redirect from="/juegos-de-laberintos-para-niños" to="/juegos-de-laberintos-para-ninos" />
						<Redirect from="/adivinanzas-faciles-y-cortas" to="/adivinanzas-para-ninos" />
						<Redirect from="/trabalenguas-cortos" to="/trabalenguas-para-ninos/cortos" />
						<Redirect from="/trabalenguas-dificiles" to="/trabalenguas-para-ninos/dificiles" />
						<Redirect from="/trabalenguas-en-ingles" to="/trabalenguas-para-ninos/en-ingles" />
						<Redirect from="/dibujos-animados/canimals" to="/dibujos-animados" />
						<Redirect from="/juegos-de-figuras-geometricas" to="/figuras-geometricas" />
						<Redirect from="/plus/juegos-de-figuras-geometricas" to="/plus/figuras-geometricas" />
						<Redirect from="/dibujos-para-colorear/figuras-geometricas" to="/figuras-geometricas" />
						<Redirect from="/plus/dibujos-para-colorear/figuras-geometricas" to="/plus/figuras-geometricas" />
						<Redirect from="/imagenes-de-animales/tigre" to="/animales-para-ninos/mamiferos/tigre" />
						<Redirect from="/plus/imagenes-de-animales/tigre" to="/plus/animales-para-ninos/mamiferos/tigre" />
						<Redirect from="/imagenes-de-animales/perezoso" to="/animales-para-ninos/mamiferos/perezoso" />
						<Redirect from="/plus/imagenes-de-animales/perezoso" to="/plus/animales-para-ninos/mamiferos/perezoso" />
						<Redirect from="/imagenes-de-animales/mariposa" to="/animales-para-ninos/invertebrados/mariposa" />
						<Redirect from="/plus/imagenes-de-animales/mariposa" to="/plus/animales-para-ninos/invertebrados/mariposa" />
						<Redirect from="/imagenes-de-animales/abeja" to="/animales-para-ninos/invertebrados/abeja" />
						<Redirect from="/plus/imagenes-de-animales/abeja" to="/plus/animales-para-ninos/invertebrados/abeja" />
						<Redirect from="/imagenes-de-animales/zorro" to="/animales-para-ninos/mamiferos/zorro" />
						<Redirect from="/plus/imagenes-de-animales/zorro" to="/plus/animales-para-ninos/mamiferos/zorro" />
						<Redirect from="/imagenes-de-animales/elefante" to="/animales-para-ninos/mamiferos/elefante" />
						<Redirect from="/plus/imagenes-de-animales/elefante" to="/plus/animales-para-ninos/mamiferos/elefante" />
						<Redirect from="/imagenes-de-animales/araña" to="/animales-para-ninos/invertebrados/araña" />
						<Redirect from="/plus/imagenes-de-animales/araña" to="/plus/animales-para-ninos/invertebrados/araña" />
						<Redirect from="/imagenes-de-animales/serpiente" to="/animales-para-ninos/reptiles/serpiente" />
						<Redirect from="/plus/imagenes-de-animales/serpiente" to="/plus/animales-para-ninos/reptiles/serpiente" />
						<Redirect from="/imagenes-de-animales/especies/tiburon" to="/animales-para-ninos/peces/tiburon" />
						<Redirect from="/plus/imagenes-de-animales/especies/tiburon" to="/plus/animales-para-ninos/peces/tiburon" />
						<Redirect from="/imagenes-de-animales/buho" to="/animales-para-ninos/aves/buho" />
						<Redirect from="/plus/imagenes-de-animales/buho" to="/plus/animales-para-ninos/aves/buho" />
						<Redirect from="/imagenes-de-animales/caballo" to="/animales-para-ninos/mamiferos/caballo" />
						<Redirect from="/plus/imagenes-de-animales/caballo" to="/plus/animales-para-ninos/mamiferos/caballo" />
						<Redirect from="/imagenes-de-animales/especies/batalla-de-selfis" to="/animales-para-ninos" />
						<Redirect from="/plus/imagenes-de-animales/especies/batalla-de-selfis" to="/plus/animales-para-ninos" />
						<Redirect from="/imagenes-de-animales/capibara" to="/animales-para-ninos/mamiferos/capibara" />
						<Redirect from="/plus/imagenes-de-animales/capibara" to="/plus/animales-para-ninos/mamiferos/capibara" />
						<Redirect from="/imagenes-de-animales/cebra" to="/animales-para-ninos/mamiferos/cebra" />
						<Redirect from="/plus/imagenes-de-animales/cebra" to="/plus/animales-para-ninos/mamiferos/cebra" />
						<Redirect from="/imagenes-de-animales/cerdo" to="/animales-para-ninos/mamiferos/cerdo" />
						<Redirect from="/plus/imagenes-de-animales/cerdo" to="/plus/animales-para-ninos/mamiferos/cerdo" />
						<Redirect from="/imagenes-de-animales/cocodrilo" to="/animales-para-ninos/reptiles/cocodrilo" />
						<Redirect from="/plus/imagenes-de-animales/cocodrilo" to="/plus/animales-para-ninos/reptiles/cocodrilo" />
						<Redirect from="/imagenes-de-animales/colibri" to="/animales-para-ninos/aves/colibri" />
						<Redirect from="/plus/imagenes-de-animales/colibri" to="/plus/animales-para-ninos/aves/colibri" />
						<Redirect from="/imagenes-de-animales/especies/insecto-sorprendente" to="/animales-para-ninos" />
						<Redirect from="/plus/imagenes-de-animales/especies/insecto-sorprendente" to="/plus/animales-para-ninos" />
						<Redirect from="/imagenes-de-animales/coyote" to="/animales-para-ninos/mamiferos/coyote" />
						<Redirect from="/plus/imagenes-de-animales/coyote" to="/plus/animales-para-ninos/mamiferos/coyote" />
						<Redirect from="/imagenes-de-animales/especies/delfin" to="/animales-para-ninos/mamiferos/delfin" />
						<Redirect from="/plus/imagenes-de-animales/especies/delfin" to="/plus/animales-para-ninos/mamiferos/delfin" />
						<Redirect from="/imagenes-de-animales/escarabajos" to="/animales-para-ninos/invertebrados/escarabajos" />
						<Redirect from="/plus/imagenes-de-animales/escarabajos" to="/plus/animales-para-ninos/invertebrados/escarabajos" />
						<Redirect from="/imagenes-de-animales/gallina" to="/animales-para-ninos/aves/gallina" />
						<Redirect from="/plus/imagenes-de-animales/gallina" to="/plus/animales-para-ninos/aves/gallina" />
						<Redirect from="/imagenes-de-animales/especies/combate-artistico" to="/animales-para-ninos" />
						<Redirect from="/plus/imagenes-de-animales/especies/combate-artistico" to="/plus/animales-para-ninos" />
						<Redirect from="/imagenes-de-animales/gato" to="/animales-para-ninos/mamiferos/gato" />
						<Redirect from="/plus/imagenes-de-animales/gato" to="/plus/animales-para-ninos/mamiferos/gato" />
						<Redirect from="/imagenes-de-animales/geco-uruplato" to="/animales-para-ninos/reptiles/geco-uruplato" />
						<Redirect from="/plus/imagenes-de-animales/geco-uruplato" to="/plus/animales-para-ninos/reptiles/geco-uruplato" />
						<Redirect from="/imagenes-de-animales/gorila" to="/animales-para-ninos/mamiferos/gorila" />
						<Redirect from="/plus/imagenes-de-animales/gorila" to="/plus/animales-para-ninos/mamiferos/gorila" />
						<Redirect from="/imagenes-de-animales/guepardo" to="/animales-para-ninos/mamiferos/guepardo" />
						<Redirect from="/plus/imagenes-de-animales/guepardo" to="/plus/animales-para-ninos/mamiferos/guepardo" />
						<Redirect from="/imagenes-de-animales/hiena" to="/animales-para-ninos/mamiferos/hiena" />
						<Redirect from="/plus/imagenes-de-animales/hiena" to="/plus/animales-para-ninos/mamiferos/hiena" />
						<Redirect from="/imagenes-de-animales/hormiga" to="/animales-para-ninos/invertebrados/hormiga" />
						<Redirect from="/plus/imagenes-de-animales/hormiga" to="/plus/animales-para-ninos/invertebrados/hormiga" />
						<Redirect from="/imagenes-de-animales/jaguar" to="/animales-para-ninos/mamiferos/jaguar" />
						<Redirect from="/plus/imagenes-de-animales/jaguar" to="/plus/animales-para-ninos/mamiferos/jaguar" />
						<Redirect from="/imagenes-de-animales/jirafa" to="/animales-para-ninos/mamiferos/jirafa" />
						<Redirect from="/plus/imagenes-de-animales/jirafa" to="/plus/animales-para-ninos/mamiferos/jirafa" />
						<Redirect from="/imagenes-de-animales/koala" to="/animales-para-ninos/mamiferos/koala" />
						<Redirect from="/plus/imagenes-de-animales/koala" to="/plus/animales-para-ninos/mamiferos/koala" />
						<Redirect from="/imagenes-de-animales/leon" to="/animales-para-ninos/mamiferos/leon" />
						<Redirect from="/plus/imagenes-de-animales/leon" to="/plus/animales-para-ninos/mamiferos/leon" />
						<Redirect from="/imagenes-de-animales/libelula" to="/animales-para-ninos/invertebrados/libelula" />
						<Redirect from="/plus/imagenes-de-animales/libelula" to="/plus/animales-para-ninos/invertebrados/libelula" />
						<Redirect from="/imagenes-de-animales/lobo" to="/animales-para-ninos/mamiferos/lobo" />
						<Redirect from="/plus/imagenes-de-animales/lobo" to="/plus/animales-para-ninos/mamiferos/lobo" />
						<Redirect from="/imagenes-de-animales/mapache" to="/animales-para-ninos/mamiferos/mapache" />
						<Redirect from="/plus/imagenes-de-animales/mapache" to="/plus/animales-para-ninos/mamiferos/mapache" />
						<Redirect from="/imagenes-de-animales/mariquita" to="/animales-para-ninos/invertebrados/mariquita" />
						<Redirect from="/plus/imagenes-de-animales/mariquita" to="/plus/animales-para-ninos/invertebrados/mariquita" />
						<Redirect from="/imagenes-de-animales/murcielago" to="/animales-para-ninos/mamiferos/murcielago" />
						<Redirect from="/plus/imagenes-de-animales/murcielago" to="/plus/animales-para-ninos/mamiferos/murcielago" />
						<Redirect from="/imagenes-de-animales/orca" to="/animales-para-ninos/mamiferos/orca" />
						<Redirect from="/plus/imagenes-de-animales/orca" to="/plus/animales-para-ninos/mamiferos/orca" />
						<Redirect from="/imagenes-de-animales/oso-hormiguero" to="/animales-para-ninos/mamiferos/oso-hormiguero" />
						<Redirect from="/plus/imagenes-de-animales/oso-hormiguero" to="/plus/animales-para-ninos/mamiferos/oso-hormiguero" />
						<Redirect from="/imagenes-de-animales/oso-panda-gigante" to="/animales-para-ninos/mamiferos/oso-panda-gigante" />
						<Redirect from="/plus/imagenes-de-animales/oso-panda-gigante" to="/plus/animales-para-ninos/mamiferos/oso-panda-gigante" />
						<Redirect from="/imagenes-de-animales/perro" to="/animales-para-ninos/mamiferos/perro" />
						<Redirect from="/plus/imagenes-de-animales/perro" to="/plus/animales-para-ninos/mamiferos/perro" />
						<Redirect from="/imagenes-de-animales/pinguino" to="/animales-para-ninos/aves/pinguino" />
						<Redirect from="/plus/imagenes-de-animales/pinguino" to="/plus/animales-para-ninos/aves/pinguino" />
						<Redirect from="/imagenes-de-animales/pulpo" to="/animales-para-ninos/invertebrados/pulpo" />
						<Redirect from="/plus/imagenes-de-animales/pulpo" to="/plus/animales-para-ninos/invertebrados/pulpo" />
						<Redirect from="/imagenes-de-animales/especies/rana-cornuda" to="/animales-para-ninos/anfibios/rana-cornuda" />
						<Redirect from="/plus/imagenes-de-animales/especies/rana-cornuda" to="/plus/animales-para-ninos/anfibios/rana-cornuda" />
						<Redirect from="/imagenes-de-animales/saltamontes" to="/animales-para-ninos/invertebrados/saltamontes" />
						<Redirect from="/plus/imagenes-de-animales/saltamontes" to="/plus/animales-para-ninos/invertebrados/saltamontes" />
						<Redirect from="/imagenes-de-animales/suricato" to="/animales-para-ninos/mamiferos/suricato" />
						<Redirect from="/plus/imagenes-de-animales/suricato" to="/plus/animales-para-ninos/mamiferos/suricato" />
						<Redirect from="/imagenes-de-animales/vaca" to="/animales-para-ninos/mamiferos/vaca" />
						<Redirect from="/plus/imagenes-de-animales/vaca" to="/plus/animales-para-ninos/mamiferos/vaca" />
						<Redirect from="/imagenes-de-animales/zarigueya" to="/animales-para-ninos/mamiferos/zarigueya" />
						<Redirect from="/plus/imagenes-de-animales/zarigueya" to="/plus/animales-para-ninos/mamiferos/zarigueya" />
						<Redirect from="/imagenes-de-animales" to="/animales-para-ninos" />
						<Redirect from="/plus/imagenes-de-animales" to="/plus/animales-para-ninos" />
						<Redirect from="/juegos-tablas-de-multiplicar/mixta" to="/juegos-tablas-de-multiplicar" />
						<Redirect from="/plus/juegos-tablas-de-multiplicar/mixta" to="/plus/juegos-tablas-de-multiplicar" />
						<Redirect from="/fracciones" to="/juegos-de-matematicas" />
						<Redirect from="/plus/fracciones" to="/plus/juegos-de-matematicas" />
						<Redirect from="/numeros-decimales" to="/juegos-de-matematicas" />
						<Redirect from="/plus/numeros-decimales" to="/plus/juegos-de-matematicas" />
						<Route component={NotFound} />
					</Switch>
    			</Suspense>
  			</Router>
		)
	}
}

export default App