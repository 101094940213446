import axios from 'axios'
import jwtDecode from 'jwt-decode'
import React from 'react'

const AuthenticationContext = React.createContext({
    isAuthenticated: false
})

const unauthenticatedDefaults = {
    isReady: false,
    isAuthenticated: false,
    isChild: false,
    hasActiveSubscription: false,
    token: null,
    tokenExpiresOn: null,
    user: null,
}

class AuthenticationContextProvider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ...unauthenticatedDefaults,
            authenticate: this.authenticate,
            logout: this.logout
        }

        axios.interceptors.request.use(config => {
            const { isAuthenticated, token } = this.state

            if (isAuthenticated) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`
                }
            }

            return config
        })

        axios.interceptors.response.use(
            r => r,
            error => {
                const { onUnauthorized } = this.props
                // if there is a response, check the status

                if (error.response) {
                    if (error.config.handleStatus === error.response.status) {
                        return Promise.reject(error)
                    }

                    if (
                        error.response.status === 401 ||
                        error.response.status === 403                     
                    ) {
                        if (onUnauthorized) {
                            onUnauthorized(error.config)
                        }

                        return Promise.reject(error)
                    }
                }

                return Promise.reject(error)
            }
        )
    }

    authenticate = ({ token, user }) => {
        const jwt = jwtDecode(token || this.state.token)

        const state = {
            isReady: true,
            isAuthenticated: true,
            isChild: jwt.permissions.indexOf('child') !== -1,
            hasActiveSubscription: user.hasActiveSubscription,
            token,
            tokenExpiresOn: new Date(jwt.exp * 1000),
            user
        }

        this.setState(state, () => {
            if (this.props.onAuthenticated) {
                this.props.onAuthenticated(state)
            }
        })

        return state
    }

    logout = async () => {
        await axios.post('/api/users/logout', {})
        this.setState({
            ...unauthenticatedDefaults,
            isReady: true
        })       
    }

    refresh = async (skipCallback = false) => {
        try {
            const { data } = await axios.post(
                '/api/users/refresh',
                {},
                { handleStatus: 401 }
            )

            return this.authenticate(data)
        } catch (error) {
            this.setState(
                {
                    isReady: true
                },
                () => {
                    if (!skipCallback && this.props.onUnauthorized) {
                        this.props.onUnauthorized()
                    }
                }
            )
        }
    }

    componentDidMount = () => {
        // try to refresh token, in case we have the cookie
        this.refresh(true)
    }

    render() {
        return (
            <AuthenticationContext.Provider value={this.state}>
                {this.props.children}
            </AuthenticationContext.Provider>
        )
    }
}

const useAuthenticationContext = () => React.useContext(AuthenticationContext)

export default AuthenticationContext

export {
    AuthenticationContext,
    AuthenticationContextProvider,
    useAuthenticationContext
}
