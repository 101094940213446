import React from 'react'
import ReactModal from 'react-modal'
import { AuthenticationContext } from './AuthenticationContext'
import LoginForm from './LoginForm'
import closecross from "../../assets/closecross.png"

ReactModal.setAppElement('#root')

class LoginModal extends React.Component {
    state = {
        isOpen: false
    }

    static contextType = AuthenticationContext

    open = () => {
        this.setState({ isOpen: true })
    }

    close = () => {
        this.setState({ isOpen: false }, () => {
            if (this.context.hasActiveSubscription) {
                this.props.history.push('/plus')
                return
            }
            this.props.history.push('/subscribe')
        })
    }

    render() {
        return (
            <ReactModal className="login-modal closecross-row" isOpen={this.state.isOpen}>
                <div className="container container-color">
                    <div className="row closecross-row justify-content-end">
                        <img className="closecross" src={closecross} alt="close cross" onClick={this.close} />
                    </div>
                    <div className="row justify-content-center closecross-row">
                        <div className="col-7 login-modal-title">
                            Iniciar sesión
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="login-modal-txt">This login is for subscribers only</p>
                        </div>
                    </div>
                    <LoginForm onLogin={this.close} className="modal-text" />
                    <div className="row">
                        <div className="col-12">
                            <a href="/reset-password"><p className="forgot-txt"> Forgot password</p></a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="login-modal-txt">Our <a href="/politica-de-privacidad">Privacy Policy</a> and <a href="/terminos-y-condiciones">Terms of Service</a> apply to use of this website.</p>
                        </div>
                    </div>
                    <div className="row closecross-row"> 
                        <div className="col-12">
                            <p className="join-title">Not a subscriber?</p>
                        </div>
                        <div className="col-12 join-link">
                            <a href="/subscribe"><button className="btn join-btn">Join today!</button></a>
                        </div>
                    </div>
                </div>
            </ReactModal>
        )
    }
}

export default LoginModal